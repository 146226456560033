<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="btn.add"><i class="el-icon-plus"></i> 新增</el-button>
      </div>
      <el-dialog title="新增权限" :visible.sync="dialogFormVisible" width="500px">
        <el-form ref="dialogForm" :model="dialogForm" :rules="rules" label-width="120px" size="mini" :show-message="false">
          <el-row>
            <el-col>
              <el-form-item label="权限名称：" prop="perm_name" required>
                <el-input v-model="dialogForm.perm_name" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col class="vg_mt_8">
              <el-form-item label="权限父级：" prop="perm_id" required>
                <el-select
                  v-model="dialogForm.perm_id"
                  filterable
                  placeholder="请选择父级权限"
                  clearable
                  @visible-change="getParentId($event)"
                >
                  <el-option v-for="item in parentId" :key="item.perm_id" :label="item.perm_name" :value="item.perm_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="vg_mt_8">
              <el-form-item label="权限简写：" prop="perm_id" required>
                <el-input v-model="dialogForm.perm_url"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog" size="small">取 消</el-button>
          <el-button type="primary" @click="submit('dialogForm')" size="small">确 定</el-button>
        </div>
      </el-dialog>
      <el-row>
        <el-col :md="16">
          <el-input class="vd_input" placeholder="输入关键字进行过滤" v-model="filterText" clearable> </el-input>
        </el-col>
        <el-col :md="16">
          <el-tree
            ref="tree"
            :data="data"
            :props="defaultProps"
            @node-click="handleNodeClick"
            :filter-node-method="filterNode"
            :default-expand-all="false"
            class="filter-tree vg_mt_16 vg_ptb_16"
          >
          </el-tree>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { permAPI } from '@api/modules/perm';
export default {
  name: 'PermList',
  data() {
    return {
      dialogForm: {
        perm_name: '',
        perm_id: null,
        perm_url: ''
      },
      filterText: '',
      dialogFormVisible: false,
      data: [],
      parentId: [],
      btn: {},
      defaultProps: {
        children: 'perm_child_list',
        label: data => data.perm_id + ' = ' + data.perm_name
      },
      rules: {}
    };
  },
  created() {
    this.initData();
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    initData() {
      this.getPerms();
    },
    handleNodeClick() {},
    filterNode(value, data) {
      if (!value) return true;
      return data.perm_name.indexOf(value) !== -1;
    },
    //弹框“取消”
    cancelDialog() {
      this.dialogFormVisible = false;
      this.resetForm('dialogForm');
    },
    //弹框表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //获取权限信息
    getPerms() {
      get(permAPI.getAllPerms)
        .then(res => {
          if (res.data.code === 0) {
            this.data = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 新增
    addRow() {
      this.dialogFormVisible = true;
    },
    // 获取parentId
    getParentId(flag) {
      if (flag === true && this.parentId.length === 0) {
        get(permAPI.getPermsLevel0).then(res => {
          if (res.data.code === 0) {
            this.parentId = res.data.data;
          }
        });
      }
    },
    //提交表单
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.saveInfo();
        } else {
          console.log('error submit!');
          return false;
        }
      });
    },
    // 保存
    saveInfo() {
      const newForm = {};
      newForm.perm_parent_id = this.dialogForm.perm_id;
      newForm.perm_name = this.dialogForm.perm_name;
      newForm.perm_url = this.dialogForm.perm_url;
      post(permAPI.addPerm, newForm).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.dialogFormVisible = false;
          this.dialogForm.perm_name = '';
          this.dialogForm.perm_id = null;
          this.dialogForm.perm_url = '';
          this.getPerms();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_input {
  width: 200px;
}
</style>
